<template>
  <BrToolBar @search="buscar"  :configFilter="configFilter">
    <modal></modal>
  </BrToolBar> 
</template>

<script>
/**
 * MIXIN
 */
import toolbar from '@/views/mixins/toolbar'

export default {
  props: ['select'],
  name: `BrToolBarCadastroRole`,
  mixins: [toolbar],
  components: { 
    BrToolBar: () => import('@/views/components/toolbar'),
    modal: () => import('./modalMessagem')
  }
}
</script>